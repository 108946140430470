import React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import Layout from '../components/Layout';
import Content from '../components/Content';
import SEO from '../components/Seo';

export default ({ data }) => (
  <Layout>
    <SEO title="Impressum" />
    <div css={tw`px-8 mx-auto sm:max-w-4xl lg:max-w-7xl`}>
      <h1
        className="underlined big"
        css={tw`my-6 font-serif text-3xl sm:text-5xl`}
      >
        Impressum:
      </h1>
      <Content content={data.imprint._rawBody} />
      <div>
        Icons made by{' '}
        <a
          href="https://www.flaticon.com/authors/darius-dan"
          title="Darius Dan"
        >
          Darius Dan
        </a>{' '}
        from{' '}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  {
    imprint: sanitySite(slug: { current: { eq: "impressum" } }) {
      title
      _rawBody
    }
  }
`;
